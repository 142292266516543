import React from 'react';

import { Link } from 'gatsby';
import {
  Container, Row, Col, Button, Card, CardBody
} from 'reactstrap';
import AOS from 'aos';

import SEO from '../components/SEO';

import Faq from "../components/FAQ/Home"
import { ClientLogoList } from '../components/ClientLogoList';
import headerGraphic from '../../static/images/2/home/4.0/Header_graphic.png';
import iconReasonsToBelieveCleverTech from '../../static/images/2/icons/ReasonsToBelieve_icon_CleverTech.svg';
import iconReasonsToBelieveKnowledge from '../../static/images/2/icons/ReasonsToBelieve_icon_Knowledge.svg';
import iconReasonsToBelieveMarketingExpert from '../../static/images/2/icons/ReasonsToBelieve_icon_MarketingExpert.svg';
import iconReasonsToBelievePause from '../../static/images/2/icons/ReasonsToBelieve_icon_Pause.svg';
import logoMadeForDrink from "../../static/images/2/logos/logo_MadeForDrink.png";
import MFDCaseStudyImage from "../../static/images/2/home/4.0/image_MadeForDrink_Ad.png";
import peopleIcon from '../../static/images/2/home/3.0/people.png';
import techIcon from '../../static/images/2/home/3.0/tech.png';
import LVMH from '../../static/images/client_logos_white/LVMH_white.png';
import Speedo from '../../static/images/client_logos_white/speedo_white.png';
import Vodafone from '../../static/images/client_logos_white/vodafone_white.png';
import Checkatrade from '../../static/images/client_logos_white/checkatrade_white.png';
import Westfield from '../../static/images/client_logos_white/westfield_white.png';
import Moonpig from '../../static/images/client_logos_white/moonpig_white.png';
import SMARTY from '../../static/images/client_logos_white/smarty_white.png';
import AZIMO from '../../static/images/client_logos_white/azimo_white.png';
import Telefonica from '../../static/images/client_logos_white/telefonica_white.png';
import localeContext from '../utils/localeContext';
import { Popup /* or Slider */ } from 'react-typeform-embed';
import { UtmContext } from '../utils/utmContext';
import { config } from '../utils/const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faChartLine, faMoneyBill, faUsers } from '@fortawesome/free-solid-svg-icons';


const IndexPage = () => {

  const { locale } = React.useContext(localeContext);
  const { utm } = React.useContext(UtmContext);

  React.useEffect(() => {
    AOS.init();
  }, []);

return(
  <>
    <SEO
      title="brillea - helping purpose-driven startups launch & grow"
      description="Acquire new customers from Facebook, Instagram and Google for £699 a month. Let brillea create, manage and optimise your ads"
    />
    <div className="section section_header section_home bk-purple-light">
      <Container>
        <Row>
          <Col className="text-center">
            <h1 className="color_white">Lean and agile performance marketing</h1>
            <p className="intro mb-4 color_white">For startups and SME's serious about growth</p>
          </Col>
        </Row>
        <Row>
          <Col
            className="homeHeaderContent"
            md={{ size: 6 }}
            sm={{ size: 12 }}
          >
            <p className="intro mb-4 color_white">Perfect for eCommerce, subscription and lead generation</p>
            <Popup 
              id={(locale === 'en-US' ? config?.typeform?.getstarted?.US : config?.typeform?.getstarted?.UK)}
              size={95} 
              hidden={{ utm }}
              tooltip={"Get started with brillea"}
              onReady={() => { window.dataLayer.push({ event: "openForm", CTA: "/", }) }}
              onSubmit={() => { window.dataLayer.push({ event: "getStartedFormComplete", CTA: "/", }) }}
            >
              <Button
                color="secondary"
                size="lg"
              >
                Book a Demo
              </Button>
            </Popup>
          </Col>
          <Col
            className="homeHeaderImage_wrapper"
            md={{ size: 6 }}
            sm={{ size: 12 }}
          >
            <div data-aos={"zoom-in"} data-aos-delay={"300"}>
            <img
              src={headerGraphic}
              alt={"brillea"}
            />
            </div>
          </Col>
        </Row>
      </Container>
    </div>

    <div className="section section_medium text-center">
      <Container>
        <Row>
          <Col className="text-center mb-4">
            <h2 className="h1 color_purple mb-4">brillea = people + tech</h2>
            <h3 className="mb-5">
            We're the perfect hybrid of human and machine. brillea's will figure out your marketing, answer your big questions and deliver the insights and results that count.
            </h3>
          </Col>
        </Row>

        <Row>
          
          <Col>
            <div className="peopleTech mt-4">
            <Card className="shadow">
              <CardBody>
                <div className="peopleTech_img" data-aos={"fade-up"}>
                  <img
                    src={peopleIcon}
                    alt={"brillea call direct"}
                  />
                </div>
                <h2 className="text-center mb-3">People</h2>
                <ul className="text-left tick_list">
                  <li><h6>
                      <FontAwesomeIcon icon={faCheck} />Expert guidance from best in class team</h6></li>
                  <li><h6>
                      <FontAwesomeIcon icon={faCheck} />Tailored growth strategies</h6></li>
                  <li><h6>
                      <FontAwesomeIcon icon={faCheck} />Copywriting that converts</h6></li>
                </ul>
              </CardBody>
            </Card>

            <Card className="shadow">
              <CardBody>
                <div className="peopleTech_img" data-aos={"fade-up"}>
                  <img
                    src={techIcon}
                    alt={"brillea call direct"}
                  />
                </div>
                <h2 className="text-center mb-3">Tech</h2>
                <ul className="text-left tick_list">
                  <li><h6>
                      <FontAwesomeIcon icon={faCheck} />Super platform put's everything at your fingertips</h6></li>
                  <li><h6>
                      <FontAwesomeIcon icon={faCheck} />Powerful data engine to make better decisions</h6></li>
                  <li><h6>
                      <FontAwesomeIcon icon={faCheck} />Testing engine to find your growth opportunities</h6></li>
                </ul>
              </CardBody>
            </Card>
            </div>
          </Col>

        </Row>
      </Container>
    </div>

    <div className="section section_large bk-purple ourMission">
      <Container className="pt-5">
        <Row>
          <Col md={{ size: 6, offset: 0 }} sm={{ size: 12, offset: 0 }}>
            <h2 className="text-white">our mission</h2>
            <h3 className="text-white">We're on a mission to democratise digital marketing...</h3>
            <p className="text-white">We are making exactly the same growth techniques that we’ve successfully used for global brands such as Three Mobile, LVMH, Vodafone &amp; Speedo accessible to small but growing businesses. <strong>Lean, agile and 'startup' are in our DNA</strong>.</p>
          </Col>
          <Col md={{ size: 6, offset: 0 }} sm={{ size: 12, offset: 0 }}>
          <div className="bk-purple-light rounded pt-3 pb-3 pr-3 pl-3 mb-0">
            <h4 className="text-yellow">Our aim is to beat other agencies &amp; freelancers by:</h4>

            <ul className="pill_list mb-0">
                <li><p data-aos={"fade-right"}>Delivering better results <FontAwesomeIcon icon={faChartLine} /></p></li>
                <li><p data-aos={"fade-right"} data-aos-delay={"300"}>Offering unrivalled value <FontAwesomeIcon icon={faMoneyBill} /></p></li>
                <li><p data-aos={"fade-right"} data-aos-delay={"600"}>Making sophisticated marketing accessible <FontAwesomeIcon icon={faUsers} /></p></li>
            </ul>
          </div>
          
          </Col>
        </Row>
      </Container>
    </div>

    <div className="section section_medium  pt-5 pb-5">
      <Container>
        <Row>

          <Col md={{ size: 6, offset: 0 }} sm={{ size: 12, offset: 0 }}>
            <h2 className="mt-4 mb-4">All the benefits of an agency without the cost or fluff.</h2>
            <p className="mb-4">We've designed brillea to outperform any comparable marketing agency. You still get all the care and attention you deserve but also benefit from more sophisticated targeting, messaging &amp; optimisation techniques.</p>
            <Button color="primary" tag={Link} to={"/how-brillea-works/"}>how brillea works</Button>
          </Col>

          <Col md={{ size: 6, offset: 0 }} sm={{ size: 12, offset: 0 }}>
            <div className="EComeCards">

              <div className="card_wrapper" data-aos={"fade-up"}>
              <Card className="box-shadow-light">
                <CardBody className="iconStacker">
                <div className="iconStacker_img img_h100 img_h100__group mb-2">
                  <img
                    src={iconReasonsToBelieveCleverTech}
                    alt={"brillea call direct"}
                  />
                  </div>
                  <div className="iconStacker_content">
                  <h4>Easy Access</h4>
                  <p>Our brillea platform puts everything at your finger tips and makes your life much, much easier.</p>
                  </div>
                </CardBody>
              </Card>
              </div>


              <div className="card_wrapper" data-aos={"fade-up"}>
              <Card className="box-shadow-light">
                <CardBody className="iconStacker">
                <div className="iconStacker_img img_h100 img_h100__group mb-2">
                  <img
                    src={iconReasonsToBelieveKnowledge}
                    alt={"brillea call direct"}
                  />
                  </div>
                  <div className="iconStacker_content">
                  <h4>Test &amp; learn</h4>
                  <p>Learn with us what works and doesn't work with our structured and proven approach to test and learn.</p>
                  </div>
                </CardBody>
              </Card>
              </div>



              <div className="card_wrapper" data-aos={"fade-up"}>
              <Card className="box-shadow-light">
                <CardBody className="iconStacker">
                <div className="iconStacker_img img_h100 img_h100__group mb-2">
                  <img
                    src={iconReasonsToBelieveMarketingExpert}
                    alt={"brillea call direct"}
                  />
                  </div>
                  <div className="iconStacker_content">
                  <h4>Tech &amp; data</h4>
                  <p>Our clever data driven tech outsmarts any human and delivers better results than any comparable agency.</p>
                  </div>
                </CardBody>
              </Card>
              </div>


              <div className="card_wrapper" data-aos={"fade-up"}>
              <Card className="box-shadow-light">
                <CardBody className="iconStacker">
                <div className="iconStacker_img img_h100 img_h100__group mb-2">
                  <img
                    src={iconReasonsToBelievePause}
                    alt={"brillea call direct"}
                  />
                  </div>
                  <div className="iconStacker_content">
                  <h4>Automation</h4>
                  <p>Automation of manual tasks means we spend less time on repetitive tasks like reporting and more time focussing on the performance of your ads.</p>
                  </div>
                </CardBody>
              </Card>
              </div>


            </div>
          </Col>

        </Row>
      </Container>
    </div>


    <div className="section section_medium section_clientLogo bk-yellow-dark pt-5 pb-5">
      <Container>
        <Row>
          <Col>
            <h3 className="mt-4 text-center">We&apos;ve already helped ambitious businesses become successes. <br className="hidden-sm"/>Don&apos;t just take our word for it...</h3>
          </Col>
        </Row>
        </Container>
            <div className="section section_medium">
        <Container>
        <Row>
          <Col>
            <div className="caseStudyCard">
              <div className="caseStudyCard_image">
                <div data-aos={"fade-up"}>
                  <img className="img_maxW" src={MFDCaseStudyImage} alt="Made For Drink brillea Ads"  />
                </div>
                <div className="caseStudyCard_image__metrics" data-aos={"fade-up"} data-aos-delay={"600"}>
                  <div className="caseStudyCard_image__metrics_desc">
                    <h6>Return on <br/>ad spend</h6>
                  </div>
                  <div className="caseStudyCard_image__metrics_value">
                    <h4>5x</h4>
                  </div>
                </div>
              </div>
              <div className="caseStudyCard_content">
              <div className="caseStudyCard_content-inner">
                <img src={logoMadeForDrink} alt="Made for Drink brillea digital marketing logo"/>
                <h3 className="pt-3">"The job that you guys have done for us has been nothing short of incredible."</h3>
                <p className="cite">Daniel Featherstone  |  Founder &amp; CEO</p>
                <Button color="primary" tag={Link} to={"/blog/made-for-drink-case-study/"}>case study</Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
            </div>
    </div>

    <div
      className="section section_large section_trueup bk-purple-dark text-center"
    >
      <Container>
        <Row>
          <Col md={{ size: 12, offset: 0 }}>
            <h3 className="mb-4">
              <span className="color_white">
              With brillea you can not benefit from the same marketing approach used to grow world famous brands by our previous incarnation 
              {' '}
              </span>
              <a href="https://trueup.com" rel="noreferrer" target="_blank" className="color_yellow underline">
                TrueUp
              </a>
              
            </h3>
            <p className="color_white">
              You can reap the benefits of our experience in growing famous brands
              {' '}
              <br />
              through Facebook, Instagram and Google advertising.
            </p>
          </Col>
        </Row>
        <Row className="pt-3">
          <ClientLogoList
            logos={[
              { name: 'LVMH', src: LVMH },
              { name: 'Speedo', src: Speedo },
              { name: 'Vodafone', src: Vodafone },
              { name: 'Checkatrade', src: Checkatrade },
              { name: 'Westfield', src: Westfield },
              { name: 'Moonpig', src: Moonpig },
              { name: 'SMARTY', src: SMARTY },
              { name: 'AZIMO', src: AZIMO },
              { name: 'Telefonica', src: Telefonica },
            ]}
            size={{ lg: '5', sm: '3' }}
          />
        </Row>
      </Container>
    </div>

    <div className="">
      <Faq title="Frequently asked questions" link="/faqs/" />
    </div>

    {/* <div className="section section_mission bk-purple">
      <Container>
        <Row>
          <Col
            md={{ size: 6 }}
            sm={{ size: 12 }}
          >
            <div data-aos={"zoom-in"}>
            <img className="img_maxW" src={CharitySocialPostGraphic} alt="help brillea make a difference"/>
            </div>
          </Col>
          <Col 
            md={{ size: 6 }}
            sm={{ size: 12 }}
            className="content"
          >
            <h3 className="h2 color_white mb-4">
              We donate a portion of our profits to charities chosen by our customers
            </h3>
            <p className="color_white">We ask our customers to choose from 3 charities to feature each year. Every time we donate, we&apos;ll send you an update and a  post for you to share on your socials, if you wish, to highlight their amazing work.</p>
          </Col>
        </Row>
      </Container>
    </div> */}
  </>
);
};
export default IndexPage;
